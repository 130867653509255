<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog.show"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Reject this Bundle?
        </v-card-title>
        <v-card-text>Are you sure you want to reject this bundle? on confirmation this bundle will effective emmediatly will be rejected.</v-card-text>
        <v-card-text>
            <v-text-field
            v-model="dialog.note"
            label="Note"
            color="theme"
            />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            :loading="actions.loading"
            :disabled="actions.loading"
            text
            @click="dialog.show = false"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            :loading="actions.loading"
            :disabled="actions.loading"
            text
            @click="$emit('confirm', dialog.note); dialog.show=false"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
    props:{
        actions:{
            type:Object, 
            required:true, 
            default:()=>({
                loading:false,
            })
        },
        dialog:{
            type:Object,
            required:true,
            default:()=>({
                show:false,
                note:""
            })
        }
    }
}
</script>

<style>

</style>